import React from "react"
import { graphql } from "gatsby"
import { Query, SanityBlogPost } from "typings/graphql"
import { App } from "components/App/App"
import { setTheme } from "helpers/Theme"
import { BlogCategory } from "components/BlogCategory/BlogCategory"

type BlogCategoryTemplateProps = {
  data: Query
  pageContext: {
    posts: Partial<SanityBlogPost>[]
  }
}

const BlogCategoryTemplate = ({
  data,
  pageContext,
}: BlogCategoryTemplateProps) => {
  const color = data.sanityBlogCategoryItem.color.opacityHex
  const title = data.sanityBlogCategoryItem.title
  const path = data.sanityBlogCategoryItem.path

  const seoData = {
    seo: data.sanityBlogCategoryItem?.seo,
    path: `blog/${path?.current}/`,
  }

  const theme = setTheme(
    data.sanityTypography,
    data.sanityDarkTheme,
    data.sanityLightTheme,
    data.sanityGlobalTheme
  )
  return (
    <App theme={theme} layout="page" mode="dark" seo={seoData}>
      <BlogCategory
        title={title}
        posts={pageContext.posts}
        color={color}
        path={path}
      />
    </App>
  )
}

export default BlogCategoryTemplate

export const categoryQuery = graphql`
  query blogCategoryQuery($id: String!) {
    sanityBlogCategoryItem(id: { eq: $id }) {
      ...BlogCategoryItem
    }
    sanityTypography {
      ...Typography
    }
    sanityDarkTheme {
      ...DarkTheme
    }
    sanityLightTheme {
      ...LightTheme
    }
    sanityGlobalTheme {
      ...GlobalTheme
    }
  }
`
